:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small, .small {
  font-size: .875em;
  font-weight: 400;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: .875em;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  color: #212529;
  margin-bottom: 1rem;
}

.table th, .table td {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th, .table-sm td {
  padding: .3rem;
}

.table-bordered, .table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #0000000d;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #00000013;
}

.table-primary, .table-primary > th, .table-primary > td {
  background-color: #b8daff;
}

.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > th, .table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > th, .table-success > td {
  background-color: #c3e6cb;
}

.table-success th, .table-success td, .table-success thead th, .table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > th, .table-info > td {
  background-color: #bee5eb;
}

.table-info th, .table-info td, .table-info thead th, .table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #ffeeba;
}

.table-warning th, .table-warning td, .table-warning thead th, .table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th, .table-danger td, .table-danger thead th, .table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > th, .table-light > td {
  background-color: #fdfdfe;
}

.table-light th, .table-light td, .table-light thead th, .table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > th, .table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > th, .table-active > td, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #00000013;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th, .table-dark td, .table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple], textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-row > .col > .valid-tooltip, .form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem) !important;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem) !important;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label:before, .custom-control-input.is-valid ~ .custom-control-label:before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control-input.is-valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
  border-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before, .custom-control-input.is-valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-row > .col > .invalid-tooltip, .form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem) !important;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem) !important;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label:before, .custom-control-input.is-invalid ~ .custom-control-label:before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
  border-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group, .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropright .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropleft .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  background-color: #e9ecef;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #212529;
  padding: .25rem 1.5rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  width: 1%;
  min-width: 0;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .form-control-plaintext + .form-control, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child), .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label, .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label:after, .input-group.has-validation > .form-control:nth-last-child(n+3), .input-group.has-validation > .custom-select:nth-last-child(n+3), .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label, .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend, .input-group-append {
  display: flex;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 2;
  position: relative;
}

.input-group-prepend .btn:focus, .input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea), .input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
  height: calc(1.5em + .5rem + 2px);
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text, .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn, .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  z-index: 1;
  min-height: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  left: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label:before, .custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  vertical-align: top;
  margin-bottom: 0;
  position: relative;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label:before {
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem;
  left: -2.25rem;
}

.custom-switch .custom-control-label:after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  top: calc(.25rem + 2px);
  left: calc(2px - 2.25rem);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-select {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.custom-file-label:after {
  z-index: 3;
  height: calc(1.5em + .75rem);
  color: #495057;
  content: "Browse";
  border-left: inherit;
  background-color: #e9ecef;
  border-radius: 0 .25rem .25rem 0;
  padding: .375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #0000;
  border-width: .5rem;
  border-color: #0000;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: #dee2e6;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  background-color: #0000;
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  margin-bottom: -1px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #000000e6;
}

.navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #000000b3;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #000000e6;
}

.navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #00000080;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #000000e6;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  min-height: 1px;
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-img-overlay {
  border-radius: calc(.25rem - 1px);
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
  flex-shrink: 0;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  .card-deck .card {
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  color: #6c757d;
  content: "/";
  padding-right: .5rem;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff80;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #28a74580;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #dc354580;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #343a4080;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  z-index: 2;
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 1rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 0;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  border-radius: .25rem;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: .25rem;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  background-color: #0000;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  opacity: 0;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  flex-basis: 350px;
  font-size: .875rem;
  box-shadow: 0 .25rem .75rem #0000001a;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  opacity: 1;
  display: block;
}

.toast.hide {
  display: none;
}

.toast-header {
  color: #6c757d;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border-bottom: 1px solid #0000000d;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  align-items: center;
  padding: .25rem .75rem;
  display: flex;
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow:before, .bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: 0;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-right .arrow:before, .bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: 0;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow:before, .bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: 0;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-left .arrow:before, .bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
  display: block;
  position: absolute;
}

.popover .arrow:before, .popover .arrow:after {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: .5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top > .arrow:before, .bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-top > .arrow:after, .bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: .5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-right > .arrow:before, .bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-right > .arrow:after, .bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: .5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom > .arrow:before, .bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-bottom > .arrow:after, .bs-popover-auto[x-placement^="bottom"] > .arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: .5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-left > .arrow:before, .bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-left > .arrow:after, .bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: .5rem .75rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid;
  border-right-color: #0000;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
  display: inline-block;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  animation: .75s linear infinite spinner-grow;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :before, :after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.fa, .fa-classic, .fa-sharp, .fas, .fa-solid, .far, .fa-regular, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas, .fa-classic, .fa-solid, .far, .fa-regular {
  font-family: "Font Awesome 6 Free";
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  height: 2em;
  vertical-align: middle;
  width: 2.5em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-1:before {
  content: "1";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-fill-drip:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-circle-chevron-right:before, .fa-chevron-circle-right:before {
  content: "";
}

.fa-at:before {
  content: "@";
}

.fa-trash-can:before, .fa-trash-alt:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-user-xmark:before, .fa-user-times:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-message:before, .fa-comment-alt:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-down-left-and-up-right-to-center:before, .fa-compress-alt:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-file-lines:before, .fa-file-alt:before, .fa-file-text:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-calendar-days:before, .fa-calendar-alt:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-volleyball:before, .fa-volleyball-ball:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-circle-minus:before, .fa-minus-circle:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-right-from-bracket:before, .fa-sign-out-alt:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-icons:before, .fa-heart-music-camera-bolt:before {
  content: "";
}

.fa-microphone-lines-slash:before, .fa-microphone-alt-slash:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-magnifying-glass-location:before, .fa-search-location:before {
  content: "";
}

.fa-forward-step:before, .fa-step-forward:before {
  content: "";
}

.fa-face-smile-beam:before, .fa-smile-beam:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-football:before, .fa-football-ball:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-angles-down:before, .fa-angle-double-down:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-beer-mug-empty:before, .fa-beer:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-arrow-up-long:before, .fa-long-arrow-up:before {
  content: "";
}

.fa-fire-flame-simple:before, .fa-burn:before {
  content: "";
}

.fa-person:before, .fa-male:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-face-grin-stars:before, .fa-grin-stars:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-spaghetti-monster-flying:before, .fa-pastafarianism:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-spoon:before, .fa-utensil-spoon:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-envelopes-bulk:before, .fa-mail-bulk:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-circle-h:before, .fa-hospital-symbol:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-address-book:before, .fa-contact-book:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-pencil:before, .fa-pencil-alt:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-paste:before, .fa-file-clipboard:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-truck-ramp-box:before, .fa-truck-loading:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-scroll-torah:before, .fa-torah:before {
  content: "";
}

.fa-broom-ball:before, .fa-quidditch:before, .fa-quidditch-broom-ball:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-box-archive:before, .fa-archive:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-arrow-down-9-1:before, .fa-sort-numeric-desc:before, .fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-face-grin-tongue-squint:before, .fa-grin-tongue-squint:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-earth-africa:before, .fa-globe-africa:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-tablet-screen-button:before, .fa-tablet-alt:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-face-flushed:before, .fa-flushed:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-bell-concierge:before, .fa-concierge-bell:before {
  content: "";
}

.fa-pen-ruler:before, .fa-pencil-ruler:before {
  content: "";
}

.fa-people-arrows:before, .fa-people-arrows-left-right:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-square-caret-right:before, .fa-caret-square-right:before {
  content: "";
}

.fa-scissors:before, .fa-cut:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-tachograph-digital:before, .fa-digital-tachograph:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-reply:before, .fa-mail-reply:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-square-minus:before, .fa-minus-square:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-square-caret-down:before, .fa-caret-square-down:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-hourglass-end:before, .fa-hourglass-3:before {
  content: "";
}

.fa-heart-crack:before, .fa-heart-broken:before {
  content: "";
}

.fa-square-up-right:before, .fa-external-link-square-alt:before {
  content: "";
}

.fa-face-kiss-beam:before, .fa-kiss-beam:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-circle-exclamation:before, .fa-exclamation-circle:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-arrow-right-from-bracket:before, .fa-sign-out:before {
  content: "";
}

.fa-circle-chevron-down:before, .fa-chevron-circle-down:before {
  content: "";
}

.fa-unlock-keyhole:before, .fa-unlock-alt:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-headphones-simple:before, .fa-headphones-alt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-circle-dollar-to-slot:before, .fa-donate:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-volume-low:before, .fa-volume-down:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-wheat-awn:before, .fa-wheat-alt:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-square-check:before, .fa-check-square:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-heading:before, .fa-header:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-list:before, .fa-list-squares:before {
  content: "";
}

.fa-square-phone-flip:before, .fa-phone-square-alt:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-circle-dot:before, .fa-dot-circle:before {
  content: "";
}

.fa-face-dizzy:before, .fa-dizzy:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-futbol:before, .fa-futbol-ball:before, .fa-soccer-ball:before {
  content: "";
}

.fa-paintbrush:before, .fa-paint-brush:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-hot-tub-person:before, .fa-hot-tub:before {
  content: "";
}

.fa-map-location:before, .fa-map-marked:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-pen-to-square:before, .fa-edit:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-share-nodes:before, .fa-share-alt:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-hourglass-half:before, .fa-hourglass-2:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-bag-shopping:before, .fa-shopping-bag:before {
  content: "";
}

.fa-arrow-down-z-a:before, .fa-sort-alpha-desc:before, .fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-hand:before, .fa-hand-paper:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-face-kiss:before, .fa-kiss:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-face-grin-tongue:before, .fa-grin-tongue:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-face-grin-wink:before, .fa-grin-wink:before {
  content: "";
}

.fa-ear-deaf:before, .fa-deaf:before, .fa-deafness:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-square-rss:before, .fa-rss-square:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-i:before {
  content: "I";
}

.fa-hryvnia-sign:before, .fa-hryvnia:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-face-grin-wide:before, .fa-grin-alt:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-staff-snake:before, .fa-rod-asclepius:before, .fa-rod-snake:before, .fa-staff-aesculapius:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-truck-medical:before, .fa-ambulance:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-g:before {
  content: "G";
}

.fa-notes-medical:before {
  content: "";
}

.fa-temperature-half:before, .fa-temperature-2:before, .fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-poo-storm:before, .fa-poo-bolt:before {
  content: "";
}

.fa-face-frown-open:before, .fa-frown-open:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-folder:before, .fa-folder-blank:before {
  content: "";
}

.fa-file-waveform:before, .fa-file-medical-alt:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-gauge:before, .fa-dashboard:before, .fa-gauge-med:before, .fa-tachometer-alt-average:before {
  content: "";
}

.fa-wand-magic-sparkles:before, .fa-magic-wand-sparkles:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-pen-clip:before, .fa-pen-alt:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-van-shuttle:before, .fa-shuttle-van:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-square-caret-left:before, .fa-caret-square-left:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-tablet-button:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-chart-area:before, .fa-area-chart:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-ban:before, .fa-cancel:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-spray-can-sparkles:before, .fa-air-freshener:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-arrow-pointer:before, .fa-mouse-pointer:before {
  content: "";
}

.fa-maximize:before, .fa-expand-arrows-alt:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-shapes:before, .fa-triangle-circle-square:before {
  content: "";
}

.fa-shuffle:before, .fa-random:before {
  content: "";
}

.fa-person-running:before, .fa-running:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-computer-mouse:before, .fa-mouse:before {
  content: "";
}

.fa-arrow-right-to-bracket:before, .fa-sign-in:before {
  content: "";
}

.fa-shop-slash:before, .fa-store-alt-slash:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-hourglass-start:before, .fa-hourglass-1:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-right-to-bracket:before, .fa-sign-in-alt:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-heart-pulse:before, .fa-heartbeat:before {
  content: "";
}

.fa-people-carry-box:before, .fa-people-carry:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-weight-scale:before, .fa-weight:before {
  content: "";
}

.fa-user-group:before, .fa-user-friends:before {
  content: "";
}

.fa-arrow-up-a-z:before, .fa-sort-alpha-up:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-face-laugh-squint:before, .fa-laugh-squint:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-circle-arrow-up:before, .fa-arrow-circle-up:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-person-walking:before, .fa-walking:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-fire:before {
  content: "";
}

.fa-bed-pulse:before, .fa-procedures:before {
  content: "";
}

.fa-shuttle-space:before, .fa-space-shuttle:before {
  content: "";
}

.fa-face-laugh:before, .fa-laugh:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-microphone-lines:before, .fa-microphone-alt:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-colon-sign:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-champagne-glasses:before, .fa-glass-cheers:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-file-arrow-up:before, .fa-file-upload:before {
  content: "";
}

.fa-wifi:before, .fa-wifi-3:before, .fa-wifi-strong:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-user-pen:before, .fa-user-edit:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-border-top-left:before, .fa-border-style:before {
  content: "";
}

.fa-map-location-dot:before, .fa-map-marked-alt:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-square-poll-vertical:before, .fa-poll:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-car-battery:before, .fa-battery-car:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-mars-stroke-right:before, .fa-mars-stroke-h:before {
  content: "";
}

.fa-hand-back-fist:before, .fa-hand-rock:before {
  content: "";
}

.fa-square-caret-up:before, .fa-caret-square-up:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-chart-bar:before, .fa-bar-chart:before {
  content: "";
}

.fa-hands-bubbles:before, .fa-hands-wash:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-eye-low-vision:before, .fa-low-vision:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-square-plus:before, .fa-plus-square:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-martini-glass:before, .fa-glass-martini-alt:before {
  content: "";
}

.fa-rotate-left:before, .fa-rotate-back:before, .fa-rotate-backward:before, .fa-undo-alt:before {
  content: "";
}

.fa-table-columns:before, .fa-columns:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-dolly:before, .fa-dolly-box:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-minimize:before, .fa-compress-arrows-alt:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-angles-right:before, .fa-angle-double-right:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-circle-play:before, .fa-play-circle:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-euro-sign:before, .fa-eur:before, .fa-euro:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-circle-check:before, .fa-check-circle:before {
  content: "";
}

.fa-circle-stop:before, .fa-stop-circle:before {
  content: "";
}

.fa-compass-drafting:before, .fa-drafting-compass:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-face-laugh-beam:before, .fa-laugh-beam:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-circle-chevron-up:before, .fa-chevron-circle-up:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-sterling-sign:before, .fa-gbp:before, .fa-pound-sign:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-arrow-down-long:before, .fa-long-arrow-down:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-reply-all:before, .fa-mail-reply-all:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-person-skating:before, .fa-skating:before {
  content: "";
}

.fa-filter-circle-dollar:before, .fa-funnel-dollar:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-circle-arrow-down:before, .fa-arrow-circle-down:before {
  content: "";
}

.fa-file-import:before, .fa-arrow-right-to-file:before {
  content: "";
}

.fa-square-arrow-up-right:before, .fa-external-link-square:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-temperature-empty:before, .fa-temperature-0:before, .fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-address-card:before, .fa-contact-card:before, .fa-vcard:before {
  content: "";
}

.fa-scale-unbalanced-flip:before, .fa-balance-scale-right:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-diamond-turn-right:before, .fa-directions:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-house-laptop:before, .fa-laptop-house:before {
  content: "";
}

.fa-face-tired:before, .fa-tired:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-cloud-arrow-up:before, .fa-cloud-upload:before, .fa-cloud-upload-alt:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-seedling:before, .fa-sprout:before {
  content: "";
}

.fa-left-right:before, .fa-arrows-alt-h:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-circle-arrow-left:before, .fa-arrow-circle-left:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-arrow-down-wide-short:before, .fa-sort-amount-asc:before, .fa-sort-amount-down:before {
  content: "";
}

.fa-cloud-bolt:before, .fa-thunderstorm:before {
  content: "";
}

.fa-text-slash:before, .fa-remove-format:before {
  content: "";
}

.fa-face-smile-wink:before, .fa-smile-wink:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-arrows-left-right:before, .fa-arrows-h:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-cloud-arrow-down:before, .fa-cloud-download:before, .fa-cloud-download-alt:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-chalkboard:before, .fa-blackboard:before {
  content: "";
}

.fa-user-large-slash:before, .fa-user-alt-slash:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-handshake-simple-slash:before, .fa-handshake-alt-slash:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-arrows-rotate:before, .fa-refresh:before, .fa-sync:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-shield-halved:before, .fa-shield-alt:before {
  content: "";
}

.fa-book-atlas:before, .fa-atlas:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-house-chimney-crack:before, .fa-house-damage:before {
  content: "";
}

.fa-file-zipper:before, .fa-file-archive:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-martini-glass-empty:before, .fa-glass-martini:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-person-skiing:before, .fa-skiing:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-a:before {
  content: "A";
}

.fa-temperature-arrow-down:before, .fa-temperature-down:before {
  content: "";
}

.fa-feather-pointed:before, .fa-feather-alt:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-snowflake:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-rectangle-ad:before, .fa-ad:before {
  content: "";
}

.fa-circle-arrow-right:before, .fa-arrow-circle-right:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-list-ol:before, .fa-list-1-2:before, .fa-list-numeric:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-money-check-dollar:before, .fa-money-check-alt:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-face-kiss-wink-heart:before, .fa-kiss-wink-heart:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-file-signature:before {
  content: "";
}

.fa-up-down-left-right:before, .fa-arrows-alt:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-star-half-stroke:before, .fa-star-half-alt:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-whiskey-glass:before, .fa-glass-whiskey:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-arrow-up-right-from-square:before, .fa-external-link:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-won-sign:before, .fa-krw:before, .fa-won:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-leaf:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-taxi:before, .fa-cab:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-chart-pie:before, .fa-pie-chart:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-face-grin-beam:before, .fa-grin-beam:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-location-pin:before, .fa-map-marker:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-helmet-safety:before, .fa-hard-hat:before, .fa-hat-hard:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-circle-right:before, .fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-face-rolling-eyes:before, .fa-meh-rolling-eyes:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-chart-line:before, .fa-line-chart:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-signs-post:before, .fa-map-signs:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-tarp:before {
  content: "";
}

.fa-screwdriver-wrench:before, .fa-tools:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-house-user:before, .fa-home-user:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-martini-glass-citrus:before, .fa-cocktail:before {
  content: "";
}

.fa-face-surprise:before, .fa-surprise:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-circle-pause:before, .fa-pause-circle:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-apple-whole:before, .fa-apple-alt:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-temperature-quarter:before, .fa-temperature-1:before, .fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-square-poll-horizontal:before, .fa-poll-h:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-backward-fast:before, .fa-fast-backward:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-basketball:before, .fa-basketball-ball:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-circle-up:before, .fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-mobile-screen-button:before, .fa-mobile-alt:before {
  content: "";
}

.fa-volume-high:before, .fa-volume-up:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-burger:before, .fa-hamburger:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-rupee-sign:before, .fa-rupee:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-circle-question:before, .fa-question-circle:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-phone-flip:before, .fa-phone-alt:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-forward-fast:before, .fa-fast-forward:before {
  content: "";
}

.fa-face-meh-blank:before, .fa-meh-blank:before {
  content: "";
}

.fa-square-parking:before, .fa-parking:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-bars-progress:before, .fa-tasks-alt:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-cart-flatbed:before, .fa-dolly-flatbed:before {
  content: "";
}

.fa-ban-smoking:before, .fa-smoking-ban:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-bus-simple:before, .fa-bus-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-face-sad-cry:before, .fa-sad-cry:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-signal:before, .fa-signal-5:before, .fa-signal-perfect:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-house-chimney:before, .fa-home-lg:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-face-frown:before, .fa-frown:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-shop:before, .fa-store-alt:before {
  content: "";
}

.fa-floppy-disk:before, .fa-save:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-scale-unbalanced:before, .fa-balance-scale-left:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-comment-dots:before, .fa-commenting:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-face-grin-squint:before, .fa-grin-squint:before {
  content: "";
}

.fa-hand-holding-dollar:before, .fa-hand-holding-usd:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hands-praying:before, .fa-praying-hands:before {
  content: "";
}

.fa-arrow-rotate-right:before, .fa-arrow-right-rotate:before, .fa-arrow-rotate-forward:before, .fa-redo:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-location-crosshairs:before, .fa-location:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-face-grin-tears:before, .fa-grin-tears:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-calendar-xmark:before, .fa-calendar-times:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-user-gear:before, .fa-user-cog:before {
  content: "";
}

.fa-arrow-up-1-9:before, .fa-sort-numeric-up:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-person-digging:before, .fa-digging:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-gauge-simple:before, .fa-gauge-simple-med:before, .fa-tachometer-average:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-quote-right:before, .fa-quote-right-alt:before {
  content: "";
}

.fa-shirt:before, .fa-t-shirt:before, .fa-tshirt:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-tenge-sign:before, .fa-tenge:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-up-right-from-square:before, .fa-external-link-alt:before {
  content: "";
}

.fa-table-cells:before, .fa-th:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-book-bible:before, .fa-bible:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-suitcase-medical:before, .fa-medkit:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-person-dress:before, .fa-female:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-business-time:before, .fa-briefcase-clock:before {
  content: "";
}

.fa-table-cells-large:before, .fa-th-large:before {
  content: "";
}

.fa-book-tanakh:before, .fa-tanakh:before {
  content: "";
}

.fa-phone-volume:before, .fa-volume-control-phone:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-cake-candles:before, .fa-birthday-cake:before, .fa-cake:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-angles-up:before, .fa-angle-double-up:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-arrow-up-9-1:before, .fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-hourglass:before, .fa-hourglass-empty:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-user-doctor:before, .fa-user-md:before {
  content: "";
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-camera:before, .fa-camera-alt:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-arrow-down-1-9:before, .fa-sort-numeric-asc:before, .fa-sort-numeric-down:before {
  content: "";
}

.fa-hand-holding-droplet:before, .fa-hand-holding-water:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-prescription-bottle-medical:before, .fa-prescription-bottle-alt:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-arrow-down-a-z:before, .fa-sort-alpha-asc:before, .fa-sort-alpha-down:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-arrow-rotate-left:before, .fa-arrow-left-rotate:before, .fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before, .fa-undo:before {
  content: "";
}

.fa-hard-drive:before, .fa-hdd:before {
  content: "";
}

.fa-face-grin-squint-tears:before, .fa-grin-squint-tears:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-rectangle-list:before, .fa-list-alt:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-person-skiing-nordic:before, .fa-skiing-nordic:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-circle-left:before, .fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-train-subway:before, .fa-subway:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-indian-rupee-sign:before, .fa-indian-rupee:before, .fa-inr:before {
  content: "";
}

.fa-crop-simple:before, .fa-crop-alt:before {
  content: "";
}

.fa-money-bill-1:before, .fa-money-bill-alt:before {
  content: "";
}

.fa-left-long:before, .fa-long-arrow-alt-left:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-minus:before, .fa-subtract:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-arrow-left-long:before, .fa-long-arrow-left:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-hands-asl-interpreting:before, .fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before, .fa-hands-american-sign-language-interpreting:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-droplet-slash:before, .fa-tint-slash:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-cart-shopping:before, .fa-shopping-cart:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-arrow-turn-up:before, .fa-level-up:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-square-root-variable:before, .fa-square-root-alt:before {
  content: "";
}

.fa-clock:before, .fa-clock-four:before {
  content: "";
}

.fa-backward-step:before, .fa-step-backward:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-timeline:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-house-chimney-medical:before, .fa-clinic-medical:before {
  content: "";
}

.fa-temperature-three-quarters:before, .fa-temperature-3:before, .fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-mobile-screen:before, .fa-mobile-android-alt:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-battery-half:before, .fa-battery-3:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-sliders:before, .fa-sliders-h:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-ellipsis-vertical:before, .fa-ellipsis-v:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-right-long:before, .fa-long-arrow-alt-right:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-tty:before, .fa-teletype:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-person-hiking:before, .fa-hiking:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-cable-car:before, .fa-tram:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-face-grin:before, .fa-grin:before {
  content: "";
}

.fa-delete-left:before, .fa-backspace:before {
  content: "";
}

.fa-eye-dropper:before, .fa-eye-dropper-empty:before, .fa-eyedropper:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-mobile:before, .fa-mobile-android:before, .fa-mobile-phone:before {
  content: "";
}

.fa-face-meh:before, .fa-meh:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-book-skull:before, .fa-book-dead:before {
  content: "";
}

.fa-id-card:before, .fa-drivers-license:before {
  content: "";
}

.fa-outdent:before, .fa-dedent:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-house:before, .fa-home:before, .fa-home-alt:before, .fa-home-lg-alt:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-file-medical:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-arrow-right-arrow-left:before, .fa-exchange:before {
  content: "";
}

.fa-rotate-right:before, .fa-redo-alt:before, .fa-rotate-forward:before {
  content: "";
}

.fa-utensils:before, .fa-cutlery:before {
  content: "";
}

.fa-arrow-up-wide-short:before, .fa-sort-amount-up:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-tower-broadcast:before, .fa-broadcast-tower:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-up-long:before, .fa-long-arrow-alt-up:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-file-arrow-down:before, .fa-file-download:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-bolt:before, .fa-zap:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-yen-sign:before, .fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen:before {
  content: "";
}

.fa-ruble-sign:before, .fa-rouble:before, .fa-rub:before, .fa-ruble:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-face-laugh-wink:before, .fa-laugh-wink:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-circle-down:before, .fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-arrow-down-short-wide:before, .fa-sort-amount-desc:before, .fa-sort-amount-down-alt:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-angle-down:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-arrow-right-long:before, .fa-long-arrow-right:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-ellipsis:before, .fa-ellipsis-h:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-kit-medical:before, .fa-first-aid:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-credit-card:before, .fa-credit-card-alt:before {
  content: "";
}

.fa-car:before, .fa-automobile:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-book-open-reader:before, .fa-book-reader:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-temperature-arrow-up:before, .fa-temperature-up:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-square-h:before, .fa-h-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-temperature-full:before, .fa-temperature-4:before, .fa-thermometer-4:before, .fa-thermometer-full:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-handshake-angle:before, .fa-hands-helping:before {
  content: "";
}

.fa-location-dot:before, .fa-map-marker-alt:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-person-swimming:before, .fa-swimmer:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-droplet:before, .fa-tint:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-earth-americas:before, .fa-earth:before, .fa-earth-america:before, .fa-globe-americas:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-battery-empty:before, .fa-battery-0:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-gauge-high:before, .fa-tachometer-alt:before, .fa-tachometer-alt-fast:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-hospital:before, .fa-hospital-alt:before, .fa-hospital-wide:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-bars-staggered:before, .fa-reorder:before, .fa-stream:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-person-walking-with-cane:before, .fa-blind:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-check-to-slot:before, .fa-vote-yea:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-boxes-stacked:before, .fa-boxes:before, .fa-boxes-alt:before {
  content: "";
}

.fa-link:before, .fa-chain:before {
  content: "";
}

.fa-ear-listen:before, .fa-assistive-listening-systems:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-magnifying-glass:before, .fa-search:before {
  content: "";
}

.fa-table-tennis-paddle-ball:before, .fa-ping-pong-paddle-ball:before, .fa-table-tennis:before {
  content: "";
}

.fa-person-dots-from-line:before, .fa-diagnoses:before {
  content: "";
}

.fa-trash-can-arrow-up:before, .fa-trash-restore-alt:before {
  content: "";
}

.fa-naira-sign:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-file-pen:before, .fa-file-edit:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-square-pen:before, .fa-pen-square:before, .fa-pencil-square:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-battery-full:before, .fa-battery:before, .fa-battery-5:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-list-ul:before, .fa-list-dots:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-down-long:before, .fa-long-arrow-alt-down:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-landmark-dome:before, .fa-landmark-alt:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-tv:before, .fa-television:before, .fa-tv-alt:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-list-check:before, .fa-tasks:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-circle-user:before, .fa-user-circle:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-car-burst:before, .fa-car-crash:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-person-snowboarding:before, .fa-snowboarding:before {
  content: "";
}

.fa-truck-fast:before, .fa-shipping-fast:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-circle-half-stroke:before, .fa-adjust:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-circle-radiation:before, .fa-radiation-alt:before {
  content: "";
}

.fa-baseball:before, .fa-baseball-ball:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-diagram-project:before, .fa-project-diagram:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-volume-xmark:before, .fa-volume-mute:before, .fa-volume-times:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-grip:before, .fa-grip-horizontal:before {
  content: "";
}

.fa-share-from-square:before, .fa-share-square:before {
  content: "";
}

.fa-child-combatant:before, .fa-child-rifle:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-square-phone:before, .fa-phone-square:before {
  content: "";
}

.fa-plus:before, .fa-add:before {
  content: "+";
}

.fa-expand:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-xmark:before, .fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before {
  content: "";
}

.fa-arrows-up-down-left-right:before, .fa-arrows:before {
  content: "";
}

.fa-chalkboard-user:before, .fa-chalkboard-teacher:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-quote-left:before, .fa-quote-left-alt:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trash-arrow-up:before, .fa-trash-restore:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-blender:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-shekel-sign:before, .fa-ils:before, .fa-shekel:before, .fa-sheqel:before, .fa-sheqel-sign:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-photo-film:before, .fa-photo-video:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-sign-hanging:before, .fa-sign:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-tablet:before, .fa-tablet-android:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-car-rear:before, .fa-car-alt:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-battery-quarter:before, .fa-battery-2:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-baby-carriage:before, .fa-carriage-baby:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-truck-moving:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-face-smile:before, .fa-smile:before {
  content: "";
}

.fa-thumbtack:before, .fa-thumb-tack:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-person-praying:before, .fa-pray:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-rotate:before, .fa-sync-alt:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-face-grin-hearts:before, .fa-grin-hearts:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-transgender:before, .fa-transgender-alt:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-arrow-turn-down:before, .fa-level-down:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-ticket-simple:before, .fa-ticket-alt:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-angles-left:before, .fa-angle-double-left:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-clock-rotate-left:before, .fa-history:before {
  content: "";
}

.fa-face-grin-beam-sweat:before, .fa-grin-beam-sweat:before {
  content: "";
}

.fa-file-export:before, .fa-arrow-right-from-file:before {
  content: "";
}

.fa-shield:before, .fa-shield-blank:before {
  content: "";
}

.fa-arrow-up-short-wide:before, .fa-sort-amount-up-alt:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-golf-ball-tee:before, .fa-golf-ball:before {
  content: "";
}

.fa-circle-chevron-left:before, .fa-chevron-circle-left:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-wand-magic:before, .fa-magic:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-wine-glass-empty:before, .fa-wine-glass-alt:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-person-biking:before, .fa-biking:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-earth-oceania:before, .fa-globe-oceania:before {
  content: "";
}

.fa-square-xmark:before, .fa-times-square:before, .fa-xmark-square:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-up-right-and-down-left-from-center:before, .fa-expand-alt:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-hippo:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-up-down:before, .fa-arrows-alt-v:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-bahai:before, .fa-haykal:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-circle-plus:before, .fa-plus-circle:before {
  content: "";
}

.fa-face-grin-tongue-wink:before, .fa-grin-tongue-wink:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-link-slash:before, .fa-chain-broken:before, .fa-chain-slash:before, .fa-unlink:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-arrow-up-z-a:before, .fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-fire-flame-curved:before, .fa-fire-alt:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-book-quran:before, .fa-quran:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-face-angry:before, .fa-angry:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-rss:before, .fa-feed:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-scale-balanced:before, .fa-balance-scale:before {
  content: "";
}

.fa-gauge-simple-high:before, .fa-tachometer:before, .fa-tachometer-fast:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-desktop:before, .fa-desktop-alt:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-table-list:before, .fa-th-list:before {
  content: "";
}

.fa-comment-sms:before, .fa-sms:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-battery-three-quarters:before, .fa-battery-4:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-hand-fist:before, .fa-fist-raised:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-image-portrait:before, .fa-portrait:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-earth-europe:before, .fa-globe-europe:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before, .fa-luggage-cart:before {
  content: "";
}

.fa-rectangle-xmark:before, .fa-rectangle-times:before, .fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-journal-whills:before, .fa-journal-whills:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-triangle-exclamation:before, .fa-exclamation-triangle:before, .fa-warning:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-share:before, .fa-arrow-turn-right:before, .fa-mail-forward:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-right-left:before, .fa-exchange-alt:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-money-bill-1-wave:before, .fa-money-bill-wave-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-hands:before, .fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-water-ladder:before, .fa-ladder-water:before, .fa-swimming-pool:before {
  content: "";
}

.fa-arrows-up-down:before, .fa-arrows-v:before {
  content: "";
}

.fa-face-grimace:before, .fa-grimace:before {
  content: "";
}

.fa-wheelchair-move:before, .fa-wheelchair-alt:before {
  content: "";
}

.fa-turn-down:before, .fa-level-down-alt:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-square-envelope:before, .fa-envelope-square:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bandage:before, .fa-band-aid:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-earth-asia:before, .fa-globe-asia:before {
  content: "";
}

.fa-id-card-clip:before, .fa-id-card-alt:before {
  content: "";
}

.fa-magnifying-glass-plus:before, .fa-search-plus:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-hand-dots:before, .fa-allergies:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-mug-saucer:before, .fa-coffee:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-magnifying-glass-minus:before, .fa-search-minus:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-user-large:before, .fa-user-alt:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-note-sticky:before, .fa-sticky-note:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-face-sad-tear:before, .fa-sad-tear:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-turkish-lira-sign:before, .fa-try:before, .fa-turkish-lira:before {
  content: "";
}

.fa-dollar-sign:before, .fa-dollar:before, .fa-usd:before {
  content: "$";
}

.fa-x:before {
  content: "X";
}

.fa-magnifying-glass-dollar:before, .fa-search-dollar:before {
  content: "";
}

.fa-users-gear:before, .fa-users-cog:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-building-columns:before, .fa-bank:before, .fa-institution:before, .fa-museum:before, .fa-university:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-stapler:before {
  content: "";
}

.fa-masks-theater:before, .fa-theater-masks:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-handshake-simple:before, .fa-handshake-alt:before {
  content: "";
}

.fa-jet-fighter:before, .fa-fighter-jet:before {
  content: "";
}

.fa-square-share-nodes:before, .fa-share-alt-square:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-video:before, .fa-video-camera:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-turn-up:before, .fa-level-up-alt:before {
  content: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.a0641c0f.woff2") format("woff2"), url("fa-brands-400.381ee61b.ttf") format("truetype");
}

.fab, .fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-square-js:before, .fa-js-square:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-square-reddit:before, .fa-reddit-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-square-instagram:before, .fa-instagram-square:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-square-hacker-news:before, .fa-hacker-news-square:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-square-snapchat:before, .fa-snapchat-square:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-square-font-awesome-stroke:before, .fa-font-awesome-alt:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before, .fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-square-dribbble:before, .fa-dribbble-square:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before, .fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before, .fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wpressr:before, .fa-rendact:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before, .fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before, .fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome:before, .fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-square-github:before, .fa-github-square:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-square-gitlab:before, .fa-gitlab-square:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-square-odnoklassniki:before, .fa-odnoklassniki-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-square-pinterest:before, .fa-pinterest-square:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-square-google-plus:before, .fa-google-plus-square:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before, .fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before, .fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-square-pied-piper:before, .fa-pied-piper-square:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-square-facebook:before, .fa-facebook-square:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-square-lastfm:before, .fa-lastfm-square:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before, .fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat:before, .fa-snapchat-ghost:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-square-behance:before, .fa-behance-square:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-square-git:before, .fa-git-square:before {
  content: "";
}

.fa-square-tumblr:before, .fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-telegram:before, .fa-telegram-plane:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-square-whatsapp:before, .fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack:before, .fa-slack-hash:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium:before, .fa-medium-m:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.5bae157a.woff2") format("woff2"), url("fa-regular-400.981cdc6e.ttf") format("truetype");
}

.far, .fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.57a8ac1f.woff2") format("woff2"), url("fa-solid-900.754eb2f2.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-display: block;
  font-weight: 400;
  src: url("fa-brands-400.a0641c0f.woff2") format("woff2"), url("fa-brands-400.381ee61b.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-display: block;
  font-weight: 900;
  src: url("fa-solid-900.57a8ac1f.woff2") format("woff2"), url("fa-solid-900.754eb2f2.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-display: block;
  font-weight: 400;
  src: url("fa-regular-400.5bae157a.woff2") format("woff2"), url("fa-regular-400.981cdc6e.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-solid-900.57a8ac1f.woff2") format("woff2"), url("fa-solid-900.754eb2f2.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-brands-400.a0641c0f.woff2") format("woff2"), url("fa-brands-400.381ee61b.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-regular-400.5bae157a.woff2") format("woff2"), url("fa-regular-400.981cdc6e.ttf") format("truetype");
  unicode-range: U+F003, U+F006, U+F014, U+F016-F017, U+F01A-F01B, U+F01D, U+F022, U+F03E, U+F044, U+F046, U+F05C-F05D, U+F06E, U+F070, U+F087-F088, U+F08A, U+F094, U+F096-F097, U+F09D, U+F0A0, U+F0A2, U+F0A4-F0A7, U+F0C5, U+F0C7, U+F0E5-F0E6, U+F0EB, U+F0F6-F0F8, U+F10C, U+F114-F115, U+F118-F11A, U+F11C-F11D, U+F133, U+F147, U+F14E, U+F150-F152, U+F185-F186, U+F18E, U+F190-F192, U+F196, U+F1C1-F1C9, U+F1D9, U+F1DB, U+F1E3, U+F1EA, U+F1F7, U+F1F9, U+F20A, U+F247-F248, U+F24A, U+F24D, U+F255-F25B, U+F25D, U+F271-F274, U+F278, U+F27B, U+F28C, U+F28E, U+F29C, U+F2B5, U+F2B7, U+F2BA, U+F2BC, U+F2BE, U+F2C0-F2C1, U+F2C3, U+F2D0, U+F2D2, U+F2D4, U+F2DC;
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-v4compatibility.c0440d6d.woff2") format("woff2"), url("fa-v4compatibility.232ac13e.ttf") format("truetype");
  unicode-range: U+F041, U+F047, U+F065-F066, U+F07D-F07E, U+F080, U+F08B, U+F08E, U+F090, U+F09A, U+F0AC, U+F0AE, U+F0B2, U+F0D0, U+F0D6, U+F0E4, U+F0EC, U+F10A-F10B, U+F123, U+F13E, U+F148-F149, U+F14C, U+F156, U+F15E, U+F160-F161, U+F163, U+F175-F178, U+F195, U+F1F8, U+F219, U+F27A;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }
}

.animate__animated.animate__flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

.owl-carousel {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  display: none;
  position: relative;
}

.owl-carousel .owl-stage {
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: relative;
}

.owl-carousel .owl-item img {
  width: 100%;
  display: block;
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  padding: 0 !important;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  height: 100%;
  background: #000;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  height: 80px;
  width: 80px;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  background: url("owl.video.play.e7a23fb2.png") no-repeat;
  margin-top: -40px;
  margin-left: -40px;
  transition: transform .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: relative;
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  float: left;
  height: 42px;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  transition: all .2s ease-in-out;
  display: block;
  position: relative;
  text-align: left !important;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  content: "";
  height: 5px;
  pointer-events: none;
  transform-origin: 66% 66%;
  width: 5px;
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  margin-top: -4px;
  transition: all .15s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: rotate(45deg);
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1)translateY(0);
}

.nice-select.disabled {
  color: #999;
  pointer-events: none;
  border-color: #ededed;
}

.nice-select.disabled:after {
  border-color: #ccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  height: 36px;
  font-size: 12px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  min-height: 34px;
  line-height: 34px;
}

.nice-select .list {
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;
  transform-origin: 50% 0;
  z-index: 9;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 4px;
  padding: 0;
  transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
  transform: scale(.75)translateY(-21px);
  box-shadow: 0 0 0 1px #4444441c;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: #0000 !important;
}

.nice-select .option {
  cursor: pointer;
  min-height: 40px;
  text-align: left;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  transition: all .2s;
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  color: #999;
  cursor: default;
  background-color: #0000;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

.mfp-bg {
  width: 100%;
  height: 100%;
  z-index: 1042;
  opacity: .8;
  background: #0b0b0b;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mfp-wrap {
  width: 100%;
  height: 100%;
  z-index: 1043;
  -webkit-backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-container:before {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  vertical-align: middle;
  text-align: left;
  z-index: 1045;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  width: auto;
  text-align: center;
  z-index: 1044;
  margin-top: -.8em;
  position: absolute;
  top: 50%;
  left: 8px;
  right: 8px;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  display: block;
  overflow: visible;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  text-align: center;
  opacity: .65;
  color: #fff;
  padding: 0 0 18px 10px;
  font-family: Arial, Baskerville, monospace;
  font-size: 28px;
  font-style: normal;
  line-height: 44px;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  text-align: right;
  width: 100%;
  padding-right: 6px;
  right: -6px;
}

.mfp-counter {
  color: #ccc;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-arrow {
  opacity: .65;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
  margin: -55px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  width: 0;
  height: 0;
  border: inset #0000;
  margin-top: 35px;
  margin-left: 35px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  opacity: .7;
  border-top-width: 21px;
  border-bottom-width: 21px;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  border-right: 27px solid #3f3f3f;
  margin-left: 25px;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  width: 100%;
  max-width: 900px;
  line-height: 0;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.mfp-iframe-scaler iframe {
  width: 100%;
  height: 100%;
  background: #000;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 8px #0009;
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 0;
  line-height: 0;
  display: block;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  width: auto;
  height: auto;
  z-index: -1;
  background: #444;
  display: block;
  position: absolute;
  inset: 40px 0;
  box-shadow: 0 0 8px #0009;
}

.mfp-figure small {
  color: #bdbdbd;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  width: 100%;
  cursor: auto;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
}

.mfp-title {
  text-align: left;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
  line-height: 18px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    margin-left: 5px;
    display: inline;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    box-sizing: border-box;
    background: #0009;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    top: 3px;
    right: 5px;
  }

  .mfp-img-mobile .mfp-close {
    width: 35px;
    height: 35px;
    text-align: center;
    background: #0009;
    padding: 0;
    line-height: 35px;
    position: fixed;
    top: 0;
    right: 0;
  }
}

@media (max-width: 900px) {
  .mfp-arrow {
    transform: scale(.75);
  }

  .mfp-arrow-left {
    transform-origin: 0;
  }

  .mfp-arrow-right {
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}



/*# sourceMappingURL=404.704835dd.css.map */
